import React from 'react'

import { Page, Report } from '../../components'
import { BlobProvider, PDFDownloadLink, Document, Page as RPage } from '@react-pdf/renderer'

import './dashboard-view.css'
import jsPDF from "jspdf";
import { renderToString } from "react-dom/server";
import {auth} from '../../services/Firebase'
import * as moment from 'moment'

const DashboardView = (props) => {
   
    const { patients } = props

    
    const print = (patient) => () => {
        const string = renderToString(<Report patient={patient}/>);
        const pdf = new jsPDF("p", "mm", "a4");
        pdf.fromHTML(string);
        pdf.save(patient.name.replace(" ", "_") + "_PhysicalExam");
    };

    const formateDate = (timestamp) => {

        const date = timestamp.toDate()
        return moment(date).format("dddd, MMMM Do YYYY, hA"); 
    }

    return (
        <Page isLoading={!patients}>
            <nav className="shadow-sm p-3 navbar sticky-top navbar-expand-lg navbar-light" 
            style={{display:'flex', alignItems:'center', backgroundColor:"white", height:'50px', borderTop:'1px solid rgb(23, 162, 184)'}}>
            <a className="navbar-brand" href="" style={{color:"#17a2b8"}}>Pysical Exam</a>
            <span style={{flex:1}}></span>
            <button type="button" className="btn btn-secondary btn-sm" onClick={auth.doSignOut}>Logout</button>
            </nav>
            <div className="page-view">
            <div className="container">
            <div className="row">
            <div className="col">
            </div>
            <div className="col-6">
            <h3 style={{textAlign:"left", color:"gray"}}>Patients</h3>
            <ul className="list-group shadow-sm">
                {
                    patients && patients.map(
                        (patient, i) => 
                        <li className="list-group-item" key={i} style={{display:'flex', alignItems:'center', minWidth:"200px"}}>
                        <div className="container-fluid">
                        <div className="row">
                            <h5>{patient.name}</h5>
                            <span style={{flex:1}}></span>
                            <button type="button" className="btn btn-light" style={{margin:'0px'}} onClick={print(patient)}>Download</button>
                        </div>
                        <div className="row" style={{minHeight:'20px', color:'gray'}}> 
                            <h6>{
                              formateDate(patient.timestamp)
                            }</h6>
                        </div>
                        </div>
                        </li>
                    )
                }
            </ul>
            </div>
            <div className="col">
            <div>
            </div>
            </div>
             </div>
            </div>
            </div>
            {/* <div style={{height:'100px', display:'flex', alignItems:'center', justifyContent:'center', backgroundColor: '#333'}}>PhysicalExam 2019 </div>  */}
        </Page>
    )
}



export default DashboardView