import React from 'react'
import { connect } from 'react-redux'
import { Route, Redirect } from 'react-router'
import { firebase } from '../services/Firebase';
import Session from '../services/SessionService/session-index'

/**
 * `PrivateRoute` - any route that requires the user to be authenticated
 * @param props 
 */
class PrivateRoute extends React.Component {

    componentDidMount() {

        const { dispatch } = this.props
        firebase.auth.onAuthStateChanged(authUser => {
         
          if (!authUser) {
            window.location = '/login'
          } else {
            dispatch(Session.Actions.setUser(authUser))
            dispatch(Session.API.fetchPatients(authUser.uid))
          }
        });
    }

    render(){

        const { path, user, component: Component } = this.props

        return (
            <Route exact path={path} render={() => (
                <Component />
            )} />
        )
    }

}

//3. map session state to props
const mapStateToProps = ({ sessionState }) => ({
    ...sessionState
})

//4. export connected `PrivateRoute` component
export default connect(mapStateToProps)(PrivateRoute)