import React from 'react'
import PropTypes from 'prop-types'

import "./page.css"

const Page = (props) => (
    <div className={`page ${props.className}`}>
        {
            props.isLoading ?
            <div style={{display:'flex', height:'100vh', backgroundColor:"white",justifyContent:"center", alignItems:"center"}}>
                <div>
                    <div className="row" style={{justifyContent:"center"}}>
                    <div class="spinner-border text-info" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    </div>
                    <div className="row" style={{padding:'10px'}}>
                        <h6>LOADING</h6>
                    </div>
                </div>
            </div> :
             props.children
        }  
    </div>
)

Page.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any
}

export default Page