//First, we create an action type 
const LOG_IN_USER = "LOG_IN_USER"
const RECEIVE_PATIENTS = "RECEIVE_PATIENTS"

//Then, we create an action to set the user
const setUser = data => ({
    type: LOG_IN_USER,
    data
})

const setPatients = data => ({
    type: RECEIVE_PATIENTS,
    data
})

//Finally, we export our action
export default {
    setUser,
    setPatients,
    LOG_IN_USER,
    RECEIVE_PATIENTS
}