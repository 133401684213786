import React from 'react';
import * as moment from 'moment'

const Report = (props) => {

    const {patient} = props

    return (
    <div>
      <h3>Patient: {patient.name}</h3>
      <h5>Date: {moment(patient.timestamp.toDate()).format("dddd, MMMM Do YYYY, h:mm:ss a")}</h5>
      <h3>Report:</h3>
      {
        patient.report.questions.map(
            (question, i) => (
                <div key={i}>
                <h4>{question.title}</h4>
                <ul className="list-group">
                {
                    question.options.map( (option,i) => (
                        option.isChecked && (
                            <li className="list-group-item" key={i}>
                        <span>{option.title}</span>
                        { option.isChecked && <span> - Yes</span>}
                        </li>
                        )
                    )
                    )
                }
                </ul>
            </div>
            )
        )
      }
      </div>
    )
}

    

export default Report