import * as firebase from 'firebase';
require("firebase/firestore");

const prodConfig = {
    apiKey: "AIzaSyAXiMZa8iNTxdEbjxe4RM9CHNeT47vH7x8",
    authDomain: "physicalexam-df714.firebaseapp.com",
    databaseURL: "https://physicalexam-df714.firebaseio.com",
    projectId: "physicalexam-df714",
    storageBucket: "physicalexam-df714.appspot.com",
    messagingSenderId: "99256832969"
  };

const devConfig = {
    apiKey: "AIzaSyAXiMZa8iNTxdEbjxe4RM9CHNeT47vH7x8",
    authDomain: "physicalexam-df714.firebaseapp.com",
    databaseURL: "https://physicalexam-df714.firebaseio.com",
    projectId: "physicalexam-df714",
    storageBucket: "physicalexam-df714.appspot.com",
    messagingSenderId: "99256832969"
  };

const config = process.env.NODE_ENV === 'production'
  ? prodConfig
  : devConfig;

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const db = firebase.database();
const fs = firebase.firestore();
const auth = firebase.auth();

export {
  db,
  fs,
  auth,
};