import { Session } from '../../services'

//1. create map state to props and return `sessionState`
export const mapStateToProps = ({ sessionState }) => ({
    ...sessionState
})


//1. create mapDispatchToProps
export const mapDispatchToProps = dispatch => {

    const { API, Actions } = Session

    return {
        fetchPatients: (uid) => API.fetchPatients(uid).then((data) => {
            dispatch(Actions.setPatients(data))
        })
    }
}